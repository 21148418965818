import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "Home | Yellow Tree"
        }
    },
    {
        path: "/about",
        name: "About",
        component: () => import(/* webpackChunkName: "aboutUs" */ "../views/AboutUs.vue"),
        meta: {
            title: "About | Yellow Tree"
        }
    },
    {
        path: "/assetInvestments",
        name: "AssetInvestments",
        component: () => import(/* webpackChunkName: "assetInvestments" */ "../views/AssetInvestments.vue"),
        meta: {
            title: "Yellow Tree Asset Investments"
        }
    },
    {
        path: "/careers",
        name: "Careers",
        component: () => import(/* webpackChunkName: "careers" */ "../views/Careers.vue"),
        meta: {
            title: "Yellow Tree Careers"
        }
    },
    {
        path: "/services",
        name: "Services",
        component: () => import(/* webpackChunkName: "services" */ "../views/Services.vue"),
        meta: {
            title: "Yellow Tree Services"
        }
    },
    {
        path: "/contact",
        name: "Contact",
        component: () => import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
        meta: {
            title: "Contact | Yellow Tree"
        }
    },
    {
        path: "/team",
        name: "Team",
        component: () => import(/* webpackChunkName: "team" */ "../views/Team.vue"),
        meta: {
            title: "Team | Yellow Tree"
        }
    },
    {
        path: "/cookiePolicy",
        name: "Cookies",
        component: () => import(/* webpackChunkName: "cookies" */ "../views/Cookies.vue"),
        meta: {
            title: "Cookie Policy | Yellow Tree"
        }
    },
    {
        path: "/privacyPolicy",
        name: "Privacy",
        component: () => import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue"),
        meta: {
            title: "Privacy Policy | Yellow Tree"
        }
    },
    {
        path: "/gdprrecruitmentpolicy",
        name: "GDPR",
        component: () => import(/* webpackChunkName: "privacy" */ "../views/Recruitment.vue"),
        meta: {
            title: "GDPR Recruitment Policy | Yellow Tree"
        }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        const el = document.querySelector("main");
        if (el) { el.scrollTo(0, 0); }
        return { top: 0 };
    }
});

router.beforeEach((to, from, next) => {
    const { title = process.env.VUE_APP_TITLE || "Yellow Tree" } = to.meta || {};
    document.title = title;
    next();
});

export default router;
